import React from "react";
import "./loading.css";

function Loading() {
  return (
    <div>
      <div className="loadingio-spinner-eclipse-ib0zttmfyj">
        <div className="ldio-0rmls6b8ped">
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default Loading;
